<template>
    <page title="消息推送" :hasBack="true" textAlign="left">
        <div class="push-item" v-for="item in pushList" :key="item.id">
            <div class="title">
                <span>{{ item.title }}</span>
                <van-switch v-model="item.status" />
            </div>
            <div class="des">
                {{ item.des }}
            </div>
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import { reactive } from 'vue';
import { Switch } from 'vant';
export default {
    name: 'MessagePush',
    components: {
        [Page.name]: Page,
        [Switch.name]: Switch,
    },
    setup() {
        const pushList = reactive([
            {
                title: '学生进出校门信息推送',
                des: '开启后系统将会为您推送学生进出校门的相关信息，让您\n' + '及时了解学生的动态~',
                status: false,
                id: 1,
            },
            { title: '食堂消费信息推送', des: '关闭后系统将不会为您推送食堂消费信息的相关信息~', status: false, id: 2 },
            {
                title: '成绩信息推送',
                des: '关闭后系统将不会为您推送每次学生考试成绩的相关信息，让您\n' + '及时了解学生的动态~',
                status: false,
                id: 3,
            },
            { title: '德育信息推送', des: '关闭后系统将不会为您推送老师对学生的表现评价信息~', status: false, id: 4 },
        ]);
        return {
            pushList,
        };
    },
};
</script>
<style lang="less" scoped>
.push-item {
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 10px;
    .title {
        display: flex;
        padding: 0 11px 0 10px;
        align-items: center;
        justify-content: space-between;
        height: 51px;
        box-sizing: border-box;
        position: relative;
        &::after {
            content: '';
            left: 34px;
            right: 0;
            height: 1px;
            position: absolute;
            bottom: 0;
            background: #f2f2f2;
        }
        span {
            font-size: 15px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #333333;
        }
        /deep/ .van-switch {
            height: 24px;
            width: 55px;
            border: none;
            background: #f5f5f5;
            border-radius: 17px;
        }
        /deep/ .van-switch--on {
            background: rgba(7, 193, 96, 0.1);
            .van-switch__node {
                background: #07c160;
            }
        }
        /deep/ .van-switch__node {
            height: 22px;
            background: #e2e2e2;
            width: 22px;
            border-radius: 50%;
            /*margin-top: 1px;*/
        }
    }

    .des {
        font-size: 13px;
        font-family: PingFang SC, serif;
        line-height: 19px;
        color: #808080;
        padding: 12px 10px 14px;
        box-sizing: border-box;
    }
}
</style>
